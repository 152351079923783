@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #abaeb3;
  border-radius: 100vh;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #abaeb3;
}
